import Script from "next/script";
import React from "react";
import { ENV_LOCAL } from "../config";

export function initSupportMessenger() {

    if (ENV_LOCAL) return null;

    return (
        <Script
            strategy="lazyOnload"
            // ${process.env .NEXT_PUBLIC_SUPPORT_WIDGET_ID}
            src={`//embed.tawk.to/64be1dd3cc26a871b02ace1f/1h63a90n2`}
            id='support-messenger'
        />
    );
}