import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as config from '../../config';
import Link from 'next/link';
import Button from "../../components/ripple/Button";
import { NAME_PROJECT, NAV_STACK_LIST } from "../../config";
import styles from './StacksCarousel.module.scss'
import Icon from "../../components/Icon";
import ImgCDN from "../../components/ImgCDN";
import { generateSlug } from "../../helpers/common";

import { BUILDER_DATA, CUSTOM_STACK_NAME } from "../../settings/builder";

const CarouselItem = forwardRef(function CarouselItem({
                                                          stack,
                                                          index,
                                                          layout,
                                                          reveal,
                                                          layoutDimensions,
                                                          collapsedHeight,
                                                          setReveal,
                                                          custom
                                                      }, ref) {

    const [wasRevealed, setWasRevealed] = useState(false);

    useEffect(() => {
        if (!wasRevealed && !!reveal) setWasRevealed(true);
    }, [reveal])

    const {accessibility} = stack;
    const link = `${NAV_STACK_LIST}/${generateSlug(stack.name)}`;
    const icon = useRef();

    const element = icon.current?.getBoundingClientRect();
    const container = layout.current?.getBoundingClientRect();
    const height = collapsedHeight ? collapsedHeight : 260;
    const shouldReveal = !reveal && container?.top && element?.top ? container.top + height < element.top : false;

    const revealIfRequired = () => {
        if (shouldReveal) setTimeout(() => setReveal(true), 50);
    }

    const showAdditionalIcons = () => {

        const isDev = stack.code.includes('-dev');
        const isExclusive = stack.code.includes('-formula');

        if (!isDev && !isExclusive) return null;

        return (
            <div className={styles['app__additional']}>
                {
                    isDev &&
                    <div className={styles['app__additional__dev']}>
                        <i className='fas fa-cubes' aria-hidden="true"/>
                    </div>
                }
                {
                    isExclusive &&
                    <div className={styles['app__additional__formula']}>
                        <ImgCDN src='/img/logo.svg' aria-hidden="true"/>
                    </div>
                }
            </div>
        )
    }

    const showDebugInfo = () => {

        if (!accessibility.comingSoon && !accessibility.hidden) return null;

        return (
            <div className={styles['app__status']}>
                {
                    accessibility.comingSoon && !accessibility.hidden &&
                    <i className='fas fa-angle-double-up' aria-hidden='true'/>
                }
                {
                    accessibility.hidden &&
                    <i className='fas fa-eye-slash' aria-hidden='true'/>
                }
            </div>
        )
    }

    return (
        <div ref={index === 0 ? ref : undefined} className={styles['app-layout']}>
            <div className={styles['app-layout__container']}>
                <Link
                    href={link}
                    prefetch={false}
                    className={styles['app']}
                    draggable='false'
                    onFocus={revealIfRequired}
                    aria-label={`Learn about ${stack.name} by ${NAME_PROJECT}`}
                    ref={icon}
                >

                    <div className={styles.app__name}>
                        <p className={styles.app__name__text}>{stack.name}</p>
                    </div>
                    <div className={styles.app__container}>
                        {
                            index > 18 && !reveal && !wasRevealed ? null :
                                <Icon
                                    className={styles.app__container__image}
                                    icon={stack.icon}
                                    alt={stack.name}
                                    fallbackImages={!!custom ? ['/images/custom-stack.svg'] : undefined}
                                    // height={70}
                                    // width={70}
                                    // optimize
                                />
                        }
                    </div>
                    {showAdditionalIcons()}
                    {showDebugInfo()}

                </Link>
            </div>
        </div>
    );
})

function StacksCarousel({stacks, layoutDimensions}) {

    const sample = useRef();
    const container = useRef();
    const layout = useRef();

    // 5 - half padding to fix floating comma issues
    const calculateCarouselHeight = () => sample.current?.offsetHeight * 2 - 5;
    const calculateContainerHeight = () => container.current?.offsetHeight;

    const [reveal, setReveal] = useState(false);
    const [collapsedHeight, setCollapsedHeight] = useState(calculateCarouselHeight());
    const [revealedHeight, setRevealedHeight] = useState(calculateContainerHeight());

    useEffect(() => {
        setCollapsedHeight(calculateCarouselHeight())
        setRevealedHeight(calculateContainerHeight())
    }, [layoutDimensions]);


    const triggerButton = bottom => {
        return (
            <div className={`${styles['trigger']} ${styles[`trigger--${bottom ? 'bottom' : 'top'}`]}`}>
                <button
                    className={styles['trigger__item']}
                    onClick={toggleStackIcons}
                    aria-label={bottom ? 'Show all stacks' : 'Minimize stack list'}
                >
                    <i
                        className={`fas fa-angle-${bottom ? 'down' : 'up'} ${styles['trigger__item__icon']}`}
                        aria-hidden='true'
                    />
                </button>
            </div>
        )
    }

    const toggleStackIcons = () => {

        setReveal(!reveal);

        if (reveal) {
            let element = document.getElementById('homepage-carousel');
            let headerOffset = 70;
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    };

    return (
        <div
            className="page-content__block"
            style={{
                '--collapsed-carousel-height': collapsedHeight + 'px',
                '--revealed-carousel-height': revealedHeight + 'px',
            }}
        >
            <div id='homepage-carousel' className={`page-content__block__container-centered ${styles['carousel']}`}>
                <p className={styles.carousel__title}>Quick Deployments</p>
                <div className={`${styles.carousel__container}${reveal ? ` ${styles['reveal']}` : ''}`}>
                    {triggerButton(false)}
                    <div className={styles.carousel__container__icons} ref={layout}>
                        <div className={styles.carousel__container__icons__container} ref={container}>
                            <CarouselItem
                                stack={BUILDER_DATA}
                                index={0}
                                ref={sample}
                                layout={layout}
                                reveal={reveal}
                                setReveal={setReveal}
                                layoutDimensions={layoutDimensions}
                                collapsedHeight={collapsedHeight}
                                key={'custom-stack'}
                                custom
                            />
                            {stacks.map((item, index) => (
                                <CarouselItem
                                    stack={item}
                                    index={index}
                                    ref={sample}
                                    layout={layout}
                                    reveal={reveal}
                                    setReveal={setReveal}
                                    layoutDimensions={layoutDimensions}
                                    collapsedHeight={collapsedHeight}
                                    key={index}
                                />
                            ))}
                            <div className={styles.carousel__container__bottom}>
                                <Link className={styles.carousel__container__bottom__item} href={config.NAV_STACK_LIST}>
                                    <Button
                                        title='Find more'
                                        iconRight='fas fa-search'
                                        visuals={{height: '5rem'}}
                                        tabIndex={-1}
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    {triggerButton(true)}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    layoutDimensions: state.UI.layoutDimensions,
});

export default connect(mapStateToProps)(StacksCarousel)