import React, { useEffect, useRef, useState } from 'react'
import LayoutFull from '../layouts/layoutFull/LayoutFull';
import { createFetchAsync } from "../helpers/fetch/fetchRequestAsync";
import * as config from "../config";
import { handleStacksData } from "../helpers/data/get/stacks";
import { generateCategories, processClouds } from "../helpers/data/get/clouds";
import getPages from "../helpers/data/get/pages";
import SeoApiHandler from "../helpers/seo/SeoApiHandler";
import Page, { Block, Content } from "../layouts/Content";
import StacksCarousel from "../pagesComponents/homepage/StacksCarousel";
import { RenderPromo } from "../pagesComponents/homepage/Promo";
import AppsCategories from "../pagesComponents/homepage/AppsCategories";
import { SupportProviders } from "../pagesComponents/homepage/SupportProviders";
import Partners from "../pagesComponents/homepage/Partners";
import TrustPilot from "../pagesComponents/homepage/Trustpilot";
import Loading from "../components/Loading";
import { initSupportMessenger } from "../components/SupportMessenger";
import { JSONLD } from "../helpers/seo/seo";
import { SEO_ORGANISATION } from "../settings/seo";
import { convertEditorStateToHTML } from "../components/draftEditor/export/exports";
import DraftHTML from "../components/draftEditor/export/DraftHTML";

export async function getStaticProps() {

    let stacksData = await createFetchAsync({
        URL: config.URL_STACKS,
    });
    let stacks = null;

    if (stacksData.success && !!stacksData.data) {

        let handledStacks = handleStacksData(stacksData.data);
        let stacksMinimized = [];
        let isRenderAllowedFor = app => !app['accessibility']['comingSoon'] && !app['accessibility']['hidden'];

        if (!config.DEBUG) handledStacks = handledStacks.filter(app => isRenderAllowedFor(app));                              // It's easier to filter result for production on this stage, instead of multiple checks in render

        handledStacks = handledStacks.sort((a, b) => {
            if (b['popularity'] - a['popularity'] === 0) {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0
            }
            return b['popularity'] - a['popularity']
        });

        handledStacks.map(stack => {
            stacksMinimized.push({
                accessibility: stack.accessibility,
                code: stack.code,
                name: stack.name,
                plan: stack.plan ? stack.plan : null,
                category: stack.category ? stack.category : [],
                icon: stack.icon,
            })
        })

        stacks = stacksMinimized
    }

    let cloudsCurrenciesCategories = await createFetchAsync({
        URL: config.URL_CLOUDS_CURRENCIES_CATEGORIES,
    });

    let categories = [];

    if (cloudsCurrenciesCategories.success && !!cloudsCurrenciesCategories.data) {
        categories = generateCategories(cloudsCurrenciesCategories.data);
        categories = categories.filter(cat => cat.tags?.some(t => stacks?.some(s => s.category.includes(t))));
    }

    const seo = await getPages('/');

    if (
        !seo?.custom?.arrival
        || !seo?.custom?.arrival?.icon?.light?.image
        || !seo?.custom?.arrival?.title
        || !seo?.custom?.arrival?.linkText
        || !seo?.custom?.arrival?.link
        || !seo?.custom?.arrival?.image
        || !seo?.custom?.arrival?.content?.blocks?.some?.(b => !!b.text)
    ) {
        throw (`Invalid data provided to pages => Homepage => New Arrival (path: '/'). Please check admin for errors.`)
    }

    const promo = {
        ...seo.custom.arrival,
        ...{
            content: convertEditorStateToHTML(seo.custom.arrival.content, {})
        }
    };

    const fistBlock = !!seo.custom?.fistBlock ? {
        ...seo.custom.fistBlock,
        ...{
            content: !!seo?.custom?.fistBlock?.content?.blocks?.some?.(b => !!b.text)
                ? convertEditorStateToHTML(seo.custom.fistBlock.content, {})
                : null
        }
    } : {};

    return {
        props: {
            stacks: stacks,
            categories: categories,
            clouds: processClouds(cloudsCurrenciesCategories?.data?.cloud),
            seo,
            promo,
            fistBlock,
        }
    };
}

export default function Home({stacks, categories, clouds, seo, promo, fistBlock}) {

    const loadTrigger = useRef();
    const [loadScripts, setLoadScripts] = useState(false);

    const handleThirdPartyLoad = e => {
        const viewHeight = window.innerHeight;
        const triggerPos = loadTrigger?.current?.getBoundingClientRect()?.top;
        if (triggerPos < viewHeight) setLoadScripts(true);
    };

    useEffect(() => {
        document?.addEventListener('scroll', handleThirdPartyLoad);
        return () => document?.removeEventListener('scroll', handleThirdPartyLoad);
    }, []);

    useEffect(() => {
        if (!!loadScripts) document?.removeEventListener('scroll', handleThirdPartyLoad);
    }, [loadScripts]);

    return (
        <LayoutFull home>
            {initSupportMessenger()}
            <SeoApiHandler data={seo}/>
            <JSONLD data={SEO_ORGANISATION} tagKey='organisation'/>
            <Page>
                {
                    !!fistBlock.content &&
                    <Block backgroundColor={`var(--colorBackgroundContent)`}>
                        <Content style={{padding: '40px 20px'}} noSection>
                            <DraftHTML
                                className={`content-editor`}
                                content={fistBlock.content}
                            />
                        </Content>
                    </Block>
                }
                <StacksCarousel stacks={stacks}/>
                <RenderPromo promo={promo}/>
                <AppsCategories categories={categories}/>
                <div ref={loadTrigger}/>
                <SupportProviders clouds={clouds}/>
                <Partners/>
                {
                    !!loadScripts ?
                        <>
                            <TrustPilot/>
                        </>
                        : <Loading type='component'/>
                }
            </Page>
        </LayoutFull>
    );
}